body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* =================== Navbar =================== */

header {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
  width: 100%;
  z-index: 99;
  position: fixed;
  top: 0%;
}

.page-top {
  margin-top: 100px;
}

.prof {
  margin: 0 50px 0 0px !important;
}

.cart {
  padding: 0 !important;
}

.multiselect {
  width: 200px !important;
}

/* .horse {
  color: #686968;
  text-decoration: none;
} */

header .logo {
  height: 70px;
  width: 100px;
}

header .header-top {
  background-color: #fff;
  border-bottom: 1px solid #d3d7cea6;
  padding: 7px 7px 3px 7px;
}
header .header-top .col-det .ulleft li {
  float: left;
  padding: 12px;
  color: #686968;
  font-size: 0.9rem;
}
.follow-li {
  padding-top: 12px !important;
}
header .header-top .col-det .ulleft li i {
  margin-right: 5px;
  color: #bfbfbf;
}
header .header-top .col-det .ulleft li span {
  margin-left: 15px;
}
header .header-top .col-det .ulright {
  float: right;
}
.ulleft img,
.ulright img {
  width: 23px;
}
header .header-top .col-det .ulright li {
  float: left;
  padding: 10px;
  color: #d3d7ce;
  font-size: 1rem;
  font-weight: 600;
}
header .header-top .col-det .ulright li i {
  margin-right: 5px;
  color: #8c8a8a;
}
header .header-top .col-det .ulright li small,
header .header-top .col-det .ulleft li small {
  color: #8c8a8a;
  font-weight: 600;
  font-size: 16px;
}
header .header-top .col-det .ulright li span {
  margin-left: 15px;
}
header .header-top .btn-bhed {
  padding-top: 7px;
  text-align: center !important;
}
header .header-top .btn-bhed .btn {
  padding: 5px 23px;
  border-radius: 50px;
  margin-left: 5px;
}

.navbar-toggler {
  position: absolute;
  right: 0px;
  border: 0px;
  padding-top: 15px;
}

header .nav-link {
  color: #686968 !important;
  padding: 5px 15px !important;
  margin: 5px 10px;
  font-weight: 700;
}
header .nav-link h5:hover {
  color: skyblue;
}

.navbar {
  padding: 2px;
}

@media (max-width: 1023px) {
  header .container {
    padding: 0px 15px;
  }
  .navbar-toggler img {
    width: 40px !important;
    margin-right: 25px;
  }
  header .logo {
    height: 70px;
    width: 80px;
  }
  .navbar {
    padding: 0px !important;
  }
  .follows {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .navbar {
    padding: 0px;
  }
  .cont {
    display: flex;
    justify-content: space-around !important;
  }
  header .header-top .col-det .ulleft li {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  header .header-top .col-det .ulleft li small {
    color: #8c8a8a;
    font-size: 13px;
  }
  .ulleft img {
    width: 20px;
  }
  header .nav-link {
    color: #686968 !important;
    border: 1px solid #ccc;
    padding: 2px 10px !important;
    border-radius: 50px;
    margin: 5px 70px;
    font-weight: 500;
    font-size: 12px;
  }
  header .header-top .btn-bhed {
    padding: 2px;
    text-align: right;
    display: flex !important;
    justify-content: center !important;
  }
  header .header-top .btn-bhed .btn {
    padding: 2px 15px;
    margin-right: 15px;
    border-radius: 50px;
    margin-left: 1px;
    font-size: 12px;
  }
  .follows {
    display: none !important;
  }
  .navbar-toggler img {
    width: 25px !important;
    margin-right: 10px;
  }
  header .logo {
    height: 55px;
    width: 60px;
  }
  .navbar-toggler {
    padding-top: 15px !important;
  }
}

.gallery-filter {
  width: 100%;
  text-align: center;
}

.gallery-filter .btn {
  background-color: #fff;
}

.gallery {
  padding: 50px;
  padding: 30px;
  background-color: #fff;
}

.filter-button {
  font-size: 15px;
  border: 1px solid #3cc88f;
  border-radius: 5px;
  text-align: center;
  color: #3cc88f;
  margin-bottom: 30px;
  margin-right: 10px;
}

.filter-button:hover {
  font-size: 15px;
  border: 1px solid black;
  border-radius: 5px;
  text-align: center;
  color: #ffffff;
  background-color: #3cc88f;
}

.accordion-header .test {
  text-align: center !important;
}

.active {
  background-color: #3cc88f;
}

.cart-badge {
  width: 30px !important;
  height: 22px;
  padding: 3px;
  padding-top: 4px;
}

.badge {
  margin-right: 10px;
  font-size: 18px;
}

.modal {
  display: block;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 80px;
  left: 0;
  top: 20;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: 2% auto; /* 15% from the top and centered */
  padding: 10px;
  border: 1px solid #888;
  width: 55%; /* Could be more or less, depending on screen size */
}

/* =================== Landing Carousel =================== */

/* .app {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fcfbfb;
}

.wrapper {
  width: 1300px;
}

.container-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.first {
  font-size: 40px;
  position: relative;
}

.text-wrapper {
  line-height: 80px;
  font-size: 80px;
  margin-top: 90px;
}

.container-img {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow: hidden;
  min-width: 1150px;
  max-width: 1300px;
  width: calc(100%-100px);
  height: 600px;
}

.item-wrapper {
  position: relative;
  overflow: hidden;
  min-width: 180px;
  cursor: pointer;
  border-radius: 20px;
  padding: 0 20px;
  flex-grow: 1;
  transition: all 0.6s ease;
}

.active {
  position: relative;
  overflow: hidden;
  min-width: 150px;
  cursor: pointer;
  border-radius: 20px;
  padding: 0 20px;
  transition: all 0.6s ease;
  flex-grow: 4;
}

.item {
  position: relative;
  width: 100%;
  height: 100%;
  background-position: top;
  background-size: auto 100%;
  border-radius: 20px;
  transition: all 0.6s ease;
  overflow: hidden;
}
.item :after {
  content: "";
  display: block;
  position: absolute;
  height: 50%;
  width: 100%;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
}
.item-active {
  position: relative;
  width: 100%;
  height: 100%;
  background-position: top;
  border-radius: 20px;
  transition: all 0.6s ease;
  overflow: hidden;
  background-size: auto 120%;
}

.container-wrap1 {
  box-sizing: border-box;
  position: absolute;
  bottom: 80px;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  padding: 0 40px;
  padding-left: 40px;
  transition: all 0.6s ease;
  opacity: 0;
}

.container-wrap1-active {
  box-sizing: border-box;
  position: absolute;
  bottom: 80px;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  padding: 0 40px;
  padding-left: 100px;
  transition: all 0.6s ease;
  opacity: 1;
}

.fullName {
  font-size: 34px;
  color: #fff;
  font-weight: 600;
  word-break: keep-all;
  height: 100%;
  width: 10vw;
}

.container-wrap2 {
  width: 120px;
  height: 120px;
  background-color: #1e1e2f;
  color: #fff;
  position: absolute;
  left: 0;
  bottom: 60px;
  z-index: 1;
  transition: all 0.6s ease;
  opacity: 1;
  display: "block";
}

.container-wrap2-active {
  width: 120px;
  height: 120px;
  background-color: #1e1e2f;
  color: #fff;
  position: absolute;
  left: 0;
  bottom: 60px;
  z-index: 1;
  transition: all 0.6s ease;
  opacity: 0;
  display: "none";
}

.shortName {
  font-size: 34px;
  transform: rotate(-90deg);
} */

.modal {
  display: block;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* =================== Mobile =================== */

@media (max-width: 767px) {
  .d-mobile {
    display: flex;
  }
  .accordion h5 {
    font-size: 12px !important;
  }
  .accordion b {
    font-size: 10px !important;
  }
}

.ad {
  background-image: url("./images/poll.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.video-card {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
